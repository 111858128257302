<template>
  <div class="create-type-select">
    <r-tabs v-model="type">
      <r-tab
        :ref="id"
        v-for="{ id, label, icon } in types"
        :id="id"
        :key="id"
        :icon="icon"
        :name="label" />
    </r-tabs>
    <r-text v-if="type === 'events'" center>
      Для этого объекта нужно выбрать тип геометрии
    </r-text>
    <r-tabs v-if="type === 'events'" v-model="geomType">
      <r-tab
        v-for="{ id, label, icon } in geomTypes"
        :id="id"
        :ref="id"
        :key="id"
        :icon="icon"
        :name="label" />
    </r-tabs>
    <r-button
      class="create-type-select__footer"
      type="primary"
      @click="clickHandler">
      Продолжить
    </r-button>
  </div>
</template>

<script>
const types = [
  {
    id: 'signs',
    label: 'Дорожный знак',
    icon: 'closed'
  },
  {
    id: 'events',
    label: 'ДТП / Перекрытие',
    icon: 'car-accident'
  }
]
const geomTypes = [
  {
    id: 'points',
    icon: 'node-layer',
    label: 'Точечный объект'
  },
  {
    id: 'lines',
    icon: 'line-layer',
    label: 'Линейный объект'
  }
]

export default {
  data() {
    return {
      type: 'signs',
      geomType: 'points',
      types,
      geomTypes
    }
  },
  methods: {
    clickHandler() {
      const { ids } = this.$store.state.model
      const type = this.type === 'signs' ? this.type : this.geomType

      const editorState = {
        id: null,
        layerId: ids[type],
        step: 1,
        type
      }

      this.$store.commit('SET_EDITOR_STATE', editorState)
    }
  }
}
</script>

<style lang="scss">
.create-type-select {
  display: grid;
  grid-gap: 1rem;
  height: 100%;

  &__footer {
    width: 100%;
  }
}
</style>
