<template>
  <div class="r-ritm-tabs">
    <ul class="r-ritm-tabs__list">
      <li
        v-for="tab in tabs"
        :key="tab.id"
        class="list-item__header"
        :class="{ active: tab.isActive }"
        @click="setActive(tab.id)">
        <r-icon v-if="tab.icon" :name="tab.icon" :size="18" />
        <span class="list-item__header-name">
          {{ tab.name || '' }}
        </span>
      </li>
    </ul>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      tabs: [],
      currentTab: ''
    }
  },
  watch: {
    value(v) {
      if ((v || v === 0) && v !== this.currentTab) this.setActive(v)
    }
  },
  mounted() {
    const v = this.value

    if (v || v === 0) {
      this.setActive(v)
      this.currentTab = v
    } else {
      const tab = this.tabs[0]
      if (tab) {
        tab.setActive(true)
      }
    }
  },
  methods: {
    setActive(id) {
      (this.tabs || []).forEach((tab) => {
        if (tab.thisIsTab) {
          tab.setActive(tab.id === id)
        }
      })
      this.currentTab = id
      this.$emit('update:modelValue', id)
    }
  }
}
</script>

<style lang="scss">
.r-ritm-tabs {
  display: grid;
  grid-gap: 1rem;
  height: 100%;
  overflow: hidden;

  &__list {
    padding: 0.25rem;
    border-radius: var(--border_radius);
    display: grid;
    align-items: center;
    justify-items: stretch;
    width: 100%;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(auto-fit, minmax(100px, auto));
    justify-content: stretch;

    .list-item {
      &__header {
        height: 1.75rem;
        display: inline-grid;
        align-items: center;
        grid-auto-flow: column;
        grid-gap: 0.25rem;
        padding: 0 0.5rem;
        justify-content: center;
        border-radius: var(--border_radius);
        transition: ease 0.25s;

        &-name {
          color: var(--text_primary);
          font-size: 14px;
          user-select: none;
          line-height: 1.75rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &:hover {
          cursor: pointer;
          background-color: var(--accent_hover);
        }

        &.active {
          background-color: var(--accent_active);
        }
      }
    }

    background-color: var(--bg_containers);
  }
}
</style>
