export * from './datasources'
export * from './error-parser'
export * from './themes'
export * from './helpers'
export * from './datetime'
export * from './json'
export * from './map'
export * from './notifications'
export * from './images'

export const getHeaders = () => {
  const headers = {}

  if (getGroupId()) {
    headers.groupid = getGroupId()
  }

  return headers
}

export const getGroupId = () => {
  return JSON.parse(localStorage.getItem('group_id'))
}

export const blobToBase64 = (blob) => {
  const reader = new FileReader()
  reader.readAsDataURL(blob)
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result)
    }
  })
}
