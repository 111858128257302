import { rDate } from '@/utils'
import cloneDeep from 'lodash.clonedeep'

const prepareExistData = (data) => {
  if (!data) return {}
  const result = {}

  Object.values(data)
    .filter((e) => e.attr_id)
    .forEach((k) => {
      result[k.attr_id] = k
    })

  return result || {}
}

export const configTransform = function (config, group, data) {
  const existingValues = prepareExistData(data)

  config = Object.values(config).map((e) => {
    e.value = existingValues[e.id]
      ? existingValues[e.id].attr_value === 'true' || false
      : true
    e.required = true
    e.comment = existingValues[e.id] ? existingValues[e.id].comment : null
    e.label = e.name
    e.exist_id = existingValues[e.id] ? existingValues[e.id].id : null

    return e
  })
  config
    .sort((a, b) => {
      if (a.order_no > b.order_no) return 1
      if (a.order_no < b.order_no) return -1
      return 0
    })
    .forEach((e) => {
      if (!group[e.group_id3]?.items && e.group_id3) {
        group[e.group_id3].items = []
        group[e.group_id3].items.push(e)
      } else if (e.group_id3) {
        group[e.group_id3].items.push(e)
      }
    })
  const newConfig = Object.values(group)
    .filter((e) => e.name !== 'Общие сведения')
    .sort((a, b) => {
      if (a.order_no > b.order_no) return 1
      if (a.order_no < b.order_no) return -1
      return 0
    })

  const newConfig2 = []

  newConfig.forEach((e, i) => {
    if (String(e.order_no).length === 1) {
      e.type = 'inspection'
      e.title = `${e.order_no}. ${e.name}`
      if (!e.lists) {
        e.lists = []
      }
      if (e.items?.length) {
        if (!e.lists) {
          e.lists = []
        }
        e.lists.push({
          id: i,
          items: e.items
        })
        delete e.items
      }

      if (e.name !== 'Наличие технической документации') {
        e.radioButtonHeaders = {
          true: 'Норма',
          false: 'Отклонения'
        }
      }
      newConfig2.push(e)
    }
  })
  newConfig.forEach((e) => {
    if (String(e.order_no).length > 1) {
      const order = Number(String(e.order_no)[0])
      const index = newConfig2.findIndex((e) => e.order_no === order)

      if (index >= 0) {
        e.title = e.name
        if (!newConfig2[index]?.lists) {
          newConfig2[index].lists = []
          newConfig2[index].lists.push(e)
        } else {
          newConfig2[index].lists.push(e)
        }
      }
    }
  })

  return cloneDeep(newConfig2)
}

export const getMainData = function (data) {
  return {
    type: 'list',
    list: [
      {
        id: '1',
        title: 'Наименование подрядной / субподрядной организации',
        value: data?.application?.vehicle?.contractor?.name || '—'
      },
      {
        id: '2',
        title: 'Представитель подрядной организации',
        value: data?.application.contractor_repr?.name || '—'
      },
      {
        id: '3',
        title: 'Место осмотра',
        value: data?.application.working_places || 'Не назначено'
      },
      {
        id: '4',
        title: 'Дата проведения',
        value: data.inspection_date
          ? rDate.format(data.inspection_date)
          : 'Не назначено'
      },
      {
        id: '5',
        title: 'Ф.И.О. участников',
        values: data?.application.applications_employees?.map(
          ({ employee }) => employee.name
        ) || ['—']
      }
    ]
  }
}

export const getGeneralData = function (data) {
  return {
    type: 'list',
    title: 'Общие сведения',
    list: [
      {
        id: '1',
        title: 'Ф.И.О. водителя',
        value: data.driver_full_name || '—'
      },
      {
        id: '2',
        title: 'Собственник транспортного средства',
        value: data.vrc_full_name || '—'
      },
      {
        id: '3',
        title: 'Марка, модель транспортного средства',
        value: data.model?.name || '—'
      },
      {
        id: '4',
        title: 'Тип и назначение транспортного средства',
        value: data.vehicle_type?.name || '—'
      },
      {
        id: '5',
        title: 'Государственный регистрационный знак',
        value: data.reg_number || '—'
      },
      {
        id: '6',
        title: 'Идентификационный номер',
        value: data.vin || '—'
      },
      {
        id: '7',
        title: 'Год выпуска транспортного средства',
        value: data.year || '—'
      },
      {
        id: '8',
        title: 'Общий пробег с начала эксплуатации',
        value: data.mileage || '—'
      }
    ]
  }
}

export const documentsModel = [
  {
    id: '1',
    title: 'Водительское удостоверение',
    attr_name: 'driver_licence'
  },
  {
    id: '2',
    title: 'Паспорт ТС (ПТС)',
    attr_name: 'passport',
    parent: 'vehicle'
  },
  {
    id: '3',
    title: 'Свидетельство о регистрации ТС',
    attr_name: 'vrc_sn',
    parent: 'vehicle'
  },
  {
    id: '4',
    title: 'Диагностическая карта',
    attr_name: 'diagnostic_card'
  },
  {
    id: '5',
    title: 'Страховой полис ОСАГО',
    attr_name: 'insurance_policy_osago'
  },
  {
    id: '6',
    title: 'Путевой лист',
    attr_name: 'waybill'
  },
  {
    id: '7',
    title: 'Страховой полис перевозчика',
    attr_name: 'driver_insurance_policy'
  },
  {
    id: '8',
    title: 'Договор фрахтования',
    attr_name: 'charter_agreement'
  },
  {
    id: '9',
    title: 'Карта водителя',
    attr_name: 'driver_card'
  }
]
