import {
  getEditorRequestConfig,
  jsonToGeojson,
  modifyLinksGeometry,
  getFirstSymbolId
} from '@/utils'
import { layersConfig } from '../configs'
import { getSignsFeatures, getRackFeatures } from '../helpers/index'

const LAYERS = ['events', 'signs']

export class BaseController {
  constructor(parent) {
    this.parent = parent
    this.$store = parent.$store
    this.mapgl = parent.mapgl
    this.handlers = {}
  }

  removeBaseLayers() {
    LAYERS.forEach((type) => {
      switch (type) {
        case 'signs':
          this.removeSingsLayer()
          break
        case 'events':
          this.removeEventsLayer()
          break
      }
    })
  }

  removeEventsLayer() {
    const { ids } = this.$store.state.model
    const id = ids.events || null

    if (!id) return

    const layers = ['events_points', 'events_lines', 'events_lines_icons']

    layers.forEach((l) => {
      if (this.mapgl.getLayer(l)) {
        this.mapgl.removeLayer(l)
      }
    })
    if (this.mapgl.getSource(id)) {
      this.mapgl.removeSource(id)
    }
  }

  removeSingsLayer() {
    const id = 'e010924a-220b-4ecf-b423-9e756ff1d18e'

    const layers = ['signs_connections', 'signs_points', 'signs_symbols']

    layers.forEach((l) => {
      if (this.mapgl.getLayer(l)) {
        this.mapgl.removeLayer(l)
      }
    })
    if (this.mapgl.getSource(id)) {
      this.mapgl.removeSource(id)
    }
  }

  async addBaseLayers() {
    this.$store.commit('LOG', 'Start loading base layers')

    const { ids } = this.$store.state.model

    LAYERS.forEach((type) => {
      if (type === 'signs' && this.parent.showSings) {
        this.addLayerByType(type, ids)
      } else {
        this.removeSingsLayer()
      }
      if (type === 'events' && this.parent.showEvents) {
        this.addLayerByType(type, ids)
      } else {
        this.removeEventsLayer()
      }
    })

    const layers = ['nodes', 'links']

    layers.forEach(async (type) => await this.addLayerByType(type, ids))
  }

  async addLayerByType(type, ids) {
    const id = ids[type]
    this.toggleRacks()

    this.$store.commit('LOG', `${type} id: ${id}`)

    async function loadModelLayer(e) {
      if (e && e.noRequest) return
      const zoom = this.mapgl.getZoom()
      const config = getEditorRequestConfig(this.parent)

      // edit config
      if (type === 'signs') {
        config.only.push('name', 'sign_icon_id', 'projection', 'rack_position')
        config.include = {
          sign_icon: {
            only: ['id', 'resource_id']
          },
          rack: {
            only: ['id', 'geom', 'projection']
          }
        }
      }

      if (type === 'events') {
        config.include = {
          event_class: {
            only: ['id', 'name']
          }
        }
        config.only.push('name', 'event_class_id')

        const { where } = this.getEventsFilter()

        if (where) {
          config.where = where
        }
      }

      try {
        this.$store.commit('ADD_LOADING_LAYER', id)
        const url = `objectInfo/${id}?config=${JSON.stringify(
          config
        )}&zoom=${zoom}`
        this.$store.commit('LOG', `start loading layer ${type} url: ${url}`)

        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url
        })
        let features = Object.values(data)
        this.$store.commit(
          'LOG',
          `${type} features length is ${features.length}`
        )

        if (type === 'signs') {
          this.addSignsLayers(id, features)
        } else {
          if (type === 'events') {
            features = features.map((f) => ({
              ...f,
              event_class_name: f.event_class ? f.event_class.name : null
            }))
          } else if (type === 'links') {
            features = modifyLinksGeometry(features)
          }

          const geojson = jsonToGeojson(features)

          if (!this.mapgl.getSource(id)) {
            this.mapgl.addSource(id, {
              type: 'geojson',
              data: geojson
            })

            if (type === 'nodes' || type === 'links') {
              const options = {
                id,
                source: id,
                ...layersConfig[type]
              }

              this.mapgl.addLayer(options)
              this.mapgl.moveLayer(id, getFirstSymbolId(this.mapgl))
            } else if (type === 'events') {
              this.addEventsLayers(id)
            }
          } else {
            this.mapgl.getSource(id).setData(geojson)
          }
        }

        this.$store.commit('REMOVE_LOADING_LAYER', id)
        this.$store.commit('LOG', `${type} loading done`)
      } catch (error) {
        this.$store.commit('LOG', `${type} loading error ${error}`)
        this.$store.commit('REMOVE_LOADING_LAYER', id)
        throw new Error(error)
      } finally {
        this.$store.commit('LOG', `end loading layer ${type}`)
      }
    }

    // add handlers
    if (type === 'signs') {
      this.addLayerHandler(type, ['signs_symbols'])
    }

    if (type === 'events') {
      this.addLayerHandler(type, [
        'events_points',
        'events_lines',
        'events_lines_icons'
      ])
    }

    this.handlers[id] = loadModelLayer.bind(this)
    await this.handlers[id]()
  }

  getEventsFilter() {
    const config = {}
    const { category, interval } = this.parent.eventsFilter
    const { eventClasses } = this.$store.state?.books || []

    if (interval?.active) {
      const { from, to } = interval.prop.interval
      const validFrom = this.parent.$rDate.format(from, 'iso')
      const validTo = this.parent.$rDate.format(to, 'iso')
      const op =
        from && to ? 'between' : from && !to ? '>' : to & !from ? '<' : null
      const value =
        from && to
          ? validFrom + '/' + validTo
          : from && !to
            ? validFrom
            : to & !from
              ? validTo
              : null

      if (op && value) {
        if (!config.where) {
          config.where = []
        }
        config.where.push({ field: 'start_time', value, op })
      }
    }

    if (category && category !== 'Все') {
      const eventId = eventClasses.find((ev) => ev.name === category)?.id
      if (eventId) {
        if (!config.where) {
          config.where = []
        }
        config.where.push({ field: 'event_class_id', value: eventId, op: '=' })
      }
    }

    return config
  }

  addEventsLayers(id) {
    this.mapgl.addLayer({
      id: 'events_points',
      source: id,
      filter: ['==', '$type', 'Point'],
      ...layersConfig.events_points
    })
    this.mapgl.addLayer({
      id: 'events_lines',
      source: id,
      filter: ['==', '$type', 'LineString'],
      ...layersConfig.events_lines
    })
    this.mapgl.addLayer({
      id: 'events_lines_icons',
      source: id,
      filter: ['==', '$type', 'LineString'],
      ...layersConfig.events_lines_icons
    })
  }

  addSignsLayers(id, features) {
    const modifiedFeatures = getSignsFeatures(features)

    const geojson = {
      type: 'FeatureCollection',
      features: modifiedFeatures
    }

    if (!this.mapgl.getSource(id)) {
      this.mapgl.addSource(id, {
        type: 'geojson',
        data: geojson
      })

      this.mapgl.addLayer({
        id: 'signs_connections',
        source: id,
        ...layersConfig.signs_connections,
        filter: ['==', '$type', 'LineString']
      })
      this.mapgl.addLayer({
        id: 'signs_points',
        source: id,
        ...layersConfig.signs_points,
        filter: ['==', ['get', 'type'], 'points']
      })
      this.mapgl.addLayer({
        id: 'signs_symbols',
        source: id,
        ...layersConfig.signs_symbols,
        filter: ['==', ['get', 'type'], 'symbols']
      })
    } else {
      this.mapgl.getSource(id).setData(geojson)
    }
  }

  updateLayers() {
    const layers = ['signs', 'events']
    const { ids } = this.$store.state.model

    layers.forEach((type) => {
      const id = ids[type]

      if (this.handlers[id]) {
        this.handlers[id]()
      }
    })
  }

  addLayerHandler(type, layerIds) {
    this.mapgl.on('click', (e) => {
      const { name } = this.parent.$route

      if (name === 'create') return

      const { x, y } = e.point
      const bbox = [
        [x - 5, y - 5],
        [x + 5, y + 5]
      ]
      const features = this.mapgl.queryRenderedFeatures(bbox, {
        layers: layerIds
      })

      if (!features.length) return

      const { properties } = features[0]

      this.openCard(type, properties.id)
    })
  }

  async toggleRacks() {
    try {
      const config = {
        where: [
          {
            field: 'geom',
            op: '!=',
            value: null
          }
        ],
        include: {
          signs: {}
        }
      }
      const { data } = await this.$store.dispatch('GET_REQUEST', {
        url: `objectInfo/telemetry.racks?config=${JSON.stringify(config)}`
      })

      this.$store.commit('SET', ['racks', data])
      const modifiedFeatures = getRackFeatures(Object.values(data))

      const geojson = {
        type: 'FeatureCollection',
        features: modifiedFeatures
      }

      const id = 'racks'
      const index = getFirstSymbolId(this.mapgl)

      if (!this.mapgl.getSource(id)) {
        this.mapgl.addSource(id, {
          type: 'geojson',
          data: geojson
        })

        this.mapgl.addLayer(
          {
            id: 'racks_connections',
            source: id,
            ...layersConfig.signs_connections,
            filter: ['==', '$type', 'LineString']
          },
          index
        )
        this.mapgl.addLayer(
          {
            id: 'racks_points',
            source: id,
            ...layersConfig.signs_points,
            filter: ['==', ['get', 'type'], 'points']
          },
          index
        )

        this.mapgl.addLayer(
          {
            id,
            source: id,
            filter: ['==', ['get', 'type'], 'symbols'],
            ...layersConfig.racks
          },
          index
        )
      } else {
        this.mapgl.getSource(id).setData(geojson)
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  async openCard(type, id) {
    this.$store.commit('SET', ['cardId', id])
    this.$store.commit('SET', ['cardType', type])
  }
}
