<template>
  <div class="create-map-poptip">
    <div class="create-map-poptip__top">
      <r-text>
        {{ text }}
      </r-text>
      <r-button @click="cancel"> Выйти </r-button>
    </div>
    <div class="create-map-poptip__bottom">
      <r-button
        simple
        :disabled="!createdObject"
        type="danger"
        @click="removeGeom">
        Очистить геометрию
      </r-button>
      <r-button
        class="create-map-poptip__next"
        type="primary"
        :disabled="!createdObject"
        @click="next">
        Далее
      </r-button>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    createdObject() {
      return this.$store.state.editorState.createdObject
    },
    geomType() {
      return this.$store.state.editorState.type
    },
    text() {
      if (this.geomType === 'lines') {
        return 'Укажите две точки для линейного перекрытия. Нажмите «Далее» для перехода к атрибутам.'
      }

      return 'Укажите одну точку, затем нажмите «Далее» для перехода к атрибутам.'
    }
  },
  methods: {
    cancel() {
      this.$store.commit('SET', ['needToRemove', true])

      setTimeout(() => {
        this.$store.commit('CLEAR_EDITOR_STATE')

        this.$router.push('/odd')
      }, 128)
    },
    removeGeom() {
      this.$store.commit('SET', ['needToRemove', true])
    },
    next() {
      this.$store.commit('SET', ['flyToGeom', this.createdObject.geometry])
      this.$store.commit('SET', ['editorState.step', 2])
    }
  }
}
</script>

<style lang="scss">
.create-map-poptip {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  right: 0.5rem;
  padding: 0.5rem;
  background-color: var(--bg_panel_primary);
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.05),
    0px -4px 33px rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0 0;
  display: grid;
  grid-gap: 0.5rem;

  &__top {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: 1fr auto;
    padding: 0.25rem 0.25rem 0.5rem 0.25rem;
    border-bottom: 1px solid var(--dividers_low_contrast);
  }

  &__bottom {
    display: grid;
    grid-gap: 0.5rem;
    grid-auto-flow: column;
    justify-content: space-between;
    padding: 0.5rem 0.25rem 0.25rem 0.25rem;
  }
}
</style>
