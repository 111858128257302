<template>
  <div class="loader">
    <img src="@/assets/images/loader.svg" alt="loader" />
  </div>
</template>

<style>
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 600;
}
</style>
