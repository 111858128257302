import store from '@/store'
export const toggleBodyTheme = value => {
  console.log('toggleBodyTheme', value, store.state.darkTheme)
  if (!value) {
    let oldClass, newClass
    store.state.darkTheme
      ? ([oldClass, newClass] = ['light-theme', 'dark-theme'])
      : ([oldClass, newClass] = ['dark-theme', 'light-theme'])
    document.body.classList.remove(oldClass)
    document.body.classList.add(newClass)
  } else {
    document.body.classList.remove(
      value === 'dark' ? 'light-theme' : 'dark-theme'
    )
    document.body.classList.add(value === 'dark' ? 'dark-theme' : 'light-theme')
    store.commit('SET', ['darkTheme', value === 'dark'])
  }
}
