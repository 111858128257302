<template>
  <ul class="odd-object-list">
    <li v-for="object in objects" :key="object.id">
      <objects-item :object="object" :type="type" />
    </li>
  </ul>
</template>

<script>
import ObjectsItem from './object-item'

export default {
  components: {
    ObjectsItem
  },
  props: {
    objects: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.odd-object-list {
  overflow-y: auto;
  width: 100%;
  display: grid;
  grid-gap: 0.5rem;
}
</style>
