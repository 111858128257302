import along from '@turf/along'
import length from '@turf/length'
import { lineString } from '@turf/helpers'
import { toMercator, toWgs84 } from '@turf/projection'
import lineOffset from '@/libs/line-offset'

export const jsonToGeojson = (json, geom_field) => {
  // parse json go geojson
  return {
    type: 'FeatureCollection',
    features: json.map((item) => ({
      type: 'Feature',
      properties: { ...item, geom: null },
      geometry: { ...item[geom_field || 'geom'] }
    }))
  }
}

export const pointArrayToGeojson = (data) => {
  return {
    type: 'FeatureCollection',
    features: data.map((i) => ({
      type: 'Feature',
      properties: {
        ...i
      },
      geometry: { type: 'Point', coordinates: i.coordinates }
    }))
  }
}

export const geomFromCoordinates = (coordinates) => {
  return {
    coordinates,
    type: 'Point'
  }
}

export const getModifiedLinkGeometry = (geometry) => {
  const options = { units: 'metres' }
  const lineLength = length({ geometry, type: 'Feature' }, options)
  let OFFSET_VALUE = 3

  if (geometry.coordinates.length === 2 && lineLength < 10) {
    OFFSET_VALUE = 0.5
  }

  const line = lineString(geometry.coordinates)

  // add points
  const { coordinates } = line.geometry

  line.geometry.coordinates = [
    coordinates[0],
    along(lineString([coordinates[0], coordinates[1]]), OFFSET_VALUE, options)
      .geometry.coordinates,
    ...coordinates.slice(1, -1),
    along(
      lineString([
        coordinates[coordinates.length - 1],
        coordinates[coordinates.length - 2]
      ]),
      OFFSET_VALUE,
      options
    ).geometry.coordinates,
    coordinates[coordinates.length - 1]
  ]

  const mercLine = toMercator(line)
  const offset = lineOffset(mercLine, OFFSET_VALUE, { units: 'metres' })

  if (offset) {
    const offsetLinkCoordinates = [
      mercLine.geometry.coordinates[0],
      ...offset.geometry.coordinates.slice(1, -1),
      mercLine.geometry.coordinates[mercLine.geometry.coordinates.length - 1]
    ]

    return toWgs84(lineString(offsetLinkCoordinates)).geometry
  }

  return geometry
}

export const modifyLinksGeometry = (features) =>
  features.map((f) => ({
    ...f,
    geom: getModifiedLinkGeometry(f.geom)
  }))
