<template>
  <div v-loading="loading" class="violations-wrapper">
    <router-view v-if="!loading" />
    <navbar />
  </div>
</template>

<script>
import navbar from '@/modules/violations/navbar/navbar'
import { getDatasourcesByDatatype, getModelChildrenIds } from '@/utils'
import { related } from '@/modules/violations/config/related'

export default {
  components: { navbar },
  data() {
    return {
      related,
      loading: false
    }
  },
  async created() {
    await this.loadEventId()
    await this.loadRelated()
  },
  methods: {
    async loadEventId() {
      try {
        const where = [{ field: 'datatype', op: '=', value: 'model' }]
        const models = await getDatasourcesByDatatype(this, where)
        const model = models.find((m) => !!m.is_main_model) || models[0]
        const { events } = getModelChildrenIds(model.children)
        this.related.event.id = events
      } catch (e) {
        console.log(e)
        this.related.event.id = null
      }
    },
    async loadRelated() {
      try {
        this.loading = true
        for (const r in this.related) {
          if (this.related[r].id) {
            try {
              const config = JSON.stringify(this.related[r].config)
              const url = `objectInfo/${this.related[r].id}?config=${config}`

              const { data } = await this.$store.dispatch('GET_REQUEST', {
                url
              })

              this.related[r].data = Object.values(data)
            } catch (e) {
              console.log(e)
            }
          }
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.$store.commit('SET', ['violations.related', this.related])
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.violations-wrapper {
  height: calc(100vh - 1rem);
  padding-bottom: 3rem;
  width: 100%;
}
</style>
