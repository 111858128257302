import turfLength from '@turf/length'
import { lineString } from '@turf/helpers'
import {
  // CommonSelectors,
  Constants,
  doubleClickZoom,
  moveFeatures
} from '@/libs/map-draw/lib-utils'
import {
  // clearAllHelpers,
  clearAllRouteGeometry,
  clearActiveStopPointsStyling,
  nearestPointOnLine,
  getFeaturesByLayerId,
  getStopPointsData,
  createStopPointsHelpers
} from '@/libs/map-draw/helpers'

export const createODDSimpleSelectMode = (MapboxDraw, map) => {
  const SimpleSelect = {}
  const customState = {
    hoveredLinkId: '',
    point: null
  }

  const { model } = map.$store.state
  const { ids } = model

  SimpleSelect.clickAnywhere = function(state) {
    // Clear the re-render selection
    const wasSelected = this.getSelectedIds()
    if (wasSelected.length) {
      this.clearSelectedFeatures()
      wasSelected.forEach((id) => this.doRender(id))
    }
    doubleClickZoom.enable(this)
    this.stopExtendedInteractions(state)

    map.$store.commit('SET', ['cardId', null])

    clearAllRouteGeometry(this.map)
    // clearReverseRouteStyling(this.map)
    clearActiveStopPointsStyling(this.map, map.$store)
  }

  SimpleSelect.dragMove = function(state, e) {
    // Dragging when drag move is enabled
    state.dragMoving = true
    e.originalEvent.stopPropagation()

    let delta

    const [feature] = this.getSelected()
    const nodesFeatures = getFeaturesByLayerId(this.map, e, ids.nodes, 50)
    const linksFeatures = getFeaturesByLayerId(this.map, e, ids.links, 100)

    const source = this.map.getSource('sp_point_helpers')

    if (nodesFeatures.length) {
      const { geometry } = nodesFeatures[0]

      delta = {
        lng: geometry.coordinates[0] - state.dragMoveLocation.lng,
        lat: geometry.coordinates[1] - state.dragMoveLocation.lat
      }

      moveFeatures(this.getSelected(), delta)

      if (source) {
        const CURfeature = {
          type: 'Feature',
          geometry
        }
        const connection = {
          geometry: {
            type: 'LineString',
            coordinates: [
              CURfeature.geometry.coordinates,
              CURfeature.geometry.coordinates
            ]
          }
        }
        const data = getStopPointsData(CURfeature, CURfeature, connection)

        createStopPointsHelpers(this.map, data)
        // set properties
        feature.updateCoordinate(
          '',
          geometry.coordinates[0],
          geometry.coordinates[1]
        )
        feature.setProperty('node_id', nodesFeatures[0]?.properties?.id)
        feature.setProperty('link_id', null)
        feature.setProperty('sign_icon_id', null)
        feature.setProperty('name', 'Новый знак')
        feature.setProperty('projection', geometry)
        feature.setProperty('point', geometry)

        this.map.fire(Constants.events.CREATE, {
          features: [feature.toGeoJSON()]
        })
      }
    } else if (linksFeatures.length) {
      // get nearest link
      let length = Infinity
      let nearestLink = linksFeatures[0]

      for (let i = 0; i < linksFeatures.length; i++) {
        const link = linksFeatures[i]
        const nearest = nearestPointOnLine(
          [e.lngLat.lng, e.lngLat.lat],
          link.geometry.coordinates
        )
        const line = lineString([
          [e.lngLat.lng, e.lngLat.lat],
          nearest.geometry.coordinates
        ])
        const lineDistance = turfLength(line, { units: 'kilometers' })

        if (lineDistance < length) {
          length = lineDistance
          nearestLink = link
        }
      }

      const nearest = nearestPointOnLine(
        [e.lngLat.lng, e.lngLat.lat],
        nearestLink.geometry.coordinates
      )

      const { coordinates } = nearest.geometry

      if (map.$store.state.cardType === 'signs') {
        const { lng, lat } = e.lngLat
        const current = {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Point',
            coordinates: [lng, lat]
          }
        }

        delta = {
          lng: current.geometry.coordinates[0] - state.dragMoveLocation.lng,
          lat: current.geometry.coordinates[1] - state.dragMoveLocation.lat
        }

        const connectionCoords = [
          nearest.geometry.coordinates,
          current.geometry.coordinates
        ]

        moveFeatures(this.getSelected(), delta)

        if (source) {
          const connection = {
            geometry: {
              type: 'LineString',
              coordinates: connectionCoords
            }
          }
          const data = getStopPointsData(nearest, current, connection)

          createStopPointsHelpers(this.map, data)
          // set properties
          feature.updateCoordinate(
            '',
            current.geometry.coordinates[0],
            current.geometry.coordinates[1]
          )
          feature.setProperty('link_id', nearestLink?.properties?.id)
          feature.setProperty('node_id', null)
          feature.setProperty('sign_icon_id', null)
          feature.setProperty('name', 'Новый знак')
          feature.setProperty('projection', nearest.geometry)
          feature.setProperty('point', current.geometry)

          this.map.fire(Constants.events.CREATE, {
            features: [feature.toGeoJSON()]
          })
        }
      } else {
        delta = {
          lng: coordinates[0] - state.dragMoveLocation.lng,
          lat: coordinates[1] - state.dragMoveLocation.lat
        }
        moveFeatures(this.getSelected(), delta)
      }
    }

    const newFeature = this.getSelected()
    const newCoords = newFeature[0].coordinates

    customState.previousCoords = newCoords
    state.dragMoveLocation = {
      lng: newCoords[0],
      lat: newCoords[1]
    }
  }

  return { ...MapboxDraw.modes.simple_select, ...SimpleSelect }
}
