<template>
  <div id="map" v-loading class="map" />
</template>

<script>
import { initMap } from './core'
import extent from 'turf-extent'

export default {
  data() {
    return {
      loading: false,
      mapgl: null,
      controllers: {},
      helpers: {}
    }
  },
  computed: {
    showSings() {
      return this.$store.state.showSings
    },
    showEvents() {
      return this.$store.state.showEvents
    },
    baseLayer() {
      return this.$store.state.initialState.baseLayer
    },
    flyToGeom() {
      return this.$store.state.flyToGeom
    },
    padding() {
      const { cardId } = this.$store.state
      const { name } = this.$route

      if (cardId || name === 'create') {
        return { bottom: 360, top: 30, left: 25, right: 50 }
      }

      return { bottom: 80, top: 30, left: 25, right: 50 }
    },
    loadingLayers() {
      return this.$store.state.loadingLayers
    },
    eventsFilter() {
      return this.$store.state.eventsFilter || {}
    }
  },
  watch: {
    '$store.state.modelLoaded'(value) {
      if (value) {
        this.setCardFromQuery()
      }
    },
    $route(val, prev) {
      if (prev.name === 'create') {
        this.controllers.editor.disableEditor()
      }
      if (val.name === 'create') {
        this.$store.commit('SET', ['cardId', null])
      }
    },
    '$store.state.editorState.step'(value) {
      if (value === 1) {
        this.controllers.editor.enableCreateMode()
      }
    },
    flyToGeom(geom) {
      if (!geom) return

      const bounds = extent(geom)
      const { center, zoom } = this.$store.state.initialState

      if (geom.coordinates !== center) {
        this.mapgl.fitBounds(bounds, {
          padding: this.padding,
          maxZoom: 17,
          essential: true
        })
      } else {
        this.mapgl.flyTo({
          center,
          zoom
        })
      }

      this.$store.commit('SET', ['flyToGeom', null])
    },
    baseLayer() {
      this.updateMapStyle()
    },
    '$store.state.needToUpdate'(value) {
      if (value) {
        this.controllers.model.loadLists()
        this.controllers.base.addBaseLayers()
        this.$store.commit('SET', ['needToUpdate', false])
      }
    },
    '$store.state.needToRemove'(value) {
      if (value) {
        this.controllers.editor.removeCreatedObject()
        this.helpers.clearCustomState()
        this.$store.commit('SET', ['needToRemove', false])
      }
    },
    '$store.state.cardId'(val) {
      if (!val && this.$route.name !== 'create' && this.controllers.editor) {
        this.controllers.editor.disableEditor()
      }
    },
    eventsFilter: {
      handler() {
        this.updateEvents()
      },
      deep: true
    },
    showSings() {
      this.updateEvents()
    },
    showEvents() {
      this.updateEvents()
    }
  },
  beforeUnmount() {
    this.$store.commit('SET', ['cardId', null])
  },
  mounted() {
    this.initMap(this)
  },
  methods: {
    async updateEvents() {
      try {
        this.loading = true

        console.log('access')
        if (!this.showEvents && !this.showSings) {
          await this.controllers.base.removeBaseLayers()
        } else {
          await this.controllers.model.loadLists()
          await this.controllers.base.addBaseLayers()
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async initMap() {
      this.loading = true
      await initMap(this)

      await this.mapgl.on('style.load', () => {
        this.mapgl.once('load', async () => {
          this.loading = false
        })
      })
    },
    setCardFromQuery() {
      const { id } = this.$route.query

      if (!id) return

      this.$store.commit('SET', ['cardId', id])
      this.$store.commit('SET', ['cardType', 'events'])
      this.$router.replace({ id: null })
    },
    async updateMapStyle() {
      this.loading = true

      await initMap(this)

      this.loading = false
    },
    getObjectsLimit() {
      return 10000
    }
  }
}
</script>

<style lang="scss">
.map {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  overflow: hidden;
}

.mapboxgl-ctrl-bottom-right {
  touch-action: manipulation;
}

.mapboxgl-ctrl-attrib {
  display: none;
}

.mapboxgl-ctrl {
  &-bottom-right {
    bottom: 30%;
  }
  &-group {
    background-color: var(--bg_surface);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05) !important;

    & button + button {
      border: 0;
    }
  }

  &-attrib.mapboxgl-compact {
    display: none;
  }

  &-zoom {
    &-out .mapboxgl-ctrl-icon {
      background-image: url('~@/assets/images/control-icons/minus.svg') !important;
    }

    &-in .mapboxgl-ctrl-icon {
      background-image: url('~@/assets/images/control-icons/add-plus.svg') !important;
    }
  }

  &-geolocate .mapboxgl-ctrl-icon {
    touch-action: manipulation;
    background-image: url('~@/assets/images/control-icons/focus-zone.svg') !important;
  }
}
</style>
