<template>
  <violations-create />
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    violationsCreate: defineAsyncComponent({
      loader: () =>
        import('@/modules/violations/violations-create.vue')
    })
  }
}
</script>
