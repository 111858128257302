<template>
  <div class="checklist-files">
    <r-button @click="clickHandler('select-photo')">
      Выбрать фотографии
    </r-button>
    <div class="checklist-files__content">
      <checklistFilesList :attr-id="attrId" />
    </div>
  </div>
</template>

<script>
import checklistFilesList from './checklist-files-list'

export default {
  components: {
    checklistFilesList
  },
  props: {
    attrId: {
      type: String,
      required: true
    },
    attrName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      checklistsUrl: this.$store.state.bdd.checklistsUrl,
      id: this.$route.query.id
    }
  },
  methods: {
    clickHandler(action) {
      switch (action) {
        case 'select-photo':
          this.$store.commit('BDD_SET_FIELD', {
            field: 'selectModalShow',
            value: true
          })
          this.$store.commit('BDD_SET_FIELD', {
            field: 'selectModalAttrId',
            value: this.attrId
          })
          this.$store.commit('BDD_SET_FIELD', {
            field: 'selectModalAttrName',
            value: this.attrName
          })
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.checklist-files {
  display: grid;
  grid-gap: 1rem;
  justify-content: start;
  overflow: hidden;
}
</style>
