export const fullDateWithDot = (date) => {
  if (!date) return '—'
  return new Date(date)
    .toLocaleDateString(undefined, {
      hour: '2-digit',
      minute: '2-digit'
    })
    .split(',')
    .join(' •')
}

export const formatMinutes = (mins) => {
  return mins > 60
    ? `${Math.trunc(mins / 60)} ч ${mins % 60} мин`
    : `${mins} мин`
}

export const dateTo24hTime = (date) => {
  return date.toLocaleTimeString().slice(0, -3)
}

export const subtractingDates = (d1, d2) => {
  const mins = Math.round(Math.abs(new Date(d2) - new Date(d1)) / 60000)
  return mins > 60
    ? `${Math.trunc(mins / 60)} ч ${mins % 60} мин`
    : `${mins} мин`
}

export const subtractTime = (t1, t2) => {
  const t1P = t1.split(':')
  const t2P = t2.split(':')
  const t1M = (t1P[0] === '24' ? 0 : t1P[0]) * 60 + +t1P[1]
  const t2M = (t2P[0] === '24' ? 0 : t2P[0]) * 60 + +t2P[1]
  const sub = t2M - t1M
  return sub < 0 ? 1440 + t2M - t1M : sub
}

export const rDate = {
  date: null,
  offset: new Date().getTimezoneOffset() / 60,
  userOffset: null,
  spare: '—',
  parsed: null,
  result: null,
  ISO: null,
  units: {
    day: 24 * 60 * 60 * 1000,
    days: 24 * 60 * 60 * 1000,
    hour: 60 * 60 * 1000,
    minute: 60 * 1000
  },
  _split: (date, offset, userOffset) => {
    const weekDayNames = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
    const monthNames = [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь'
    ]

    const twoDigit = (v) => {
      return v >= 10 ? v : `0${v}`
    }

    const getOffset = (offset, userOffset) => {
      const os = userOffset ?? offset
      const h = Math.abs(Math.floor(os))
      const m = Math.abs((os % 1).toFixed(1) * 10)
      return `${os >= 0 ? '-' : '+'}${twoDigit(h)}:${twoDigit(m)}`
    }

    if (typeof userOffset === 'number') {
      const h = date.getHours()
      const abs = Math.abs(userOffset)
      const o = userOffset >= 0 ? h - abs : h + abs
      date.setHours(offset + o)
    }

    const year = date.getFullYear()
    const month = date.getMonth()
    const dateNumber = date.getDate()
    const day = date.getDay()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()

    return {
      YYYY: year,
      YY: String(year).slice(2, 4),
      MMMM: monthNames[month],
      MMM: monthNames[month]?.slice(0, 3),
      MM: twoDigit(month + 1),
      M: month + 1,
      DD: twoDigit(dateNumber),
      D: dateNumber,
      dd: weekDayNames[day],
      HH: twoDigit(hours),
      h: hours % 12 || 12,
      mm: twoDigit(minutes),
      ss: twoDigit(seconds),
      offset: getOffset(offset, userOffset),
      _instance: date
    }
  },
  _reset() {
    this.spare = '—'
    this.date = null
    this.parsed = null
    this.userOffset = null
  },
  _getOffset() {
    this.spare = '—'
    this.date = null
    this.parsed = null
    this.userOffset = null
  },
  _isValid: (d) => {
    const date = new Date(d)
    return d && !isNaN(Date.parse(date))
  },
  _parseMask(m, parsedDate) {
    let mask = m
    const keys = Object.keys(parsedDate)
    keys.forEach((k) => {
      mask = mask.replace(k, parsedDate[k])
    })
    this._reset()
    return mask
  },
  getIso(parsedDate) {
    const { YYYY, MM, DD, HH, mm, ss, offset } = parsedDate
    return `${YYYY}-${MM}-${DD}T${HH}:${mm}:${ss}${offset}`
  },
  format(d, m, spare) {
    if (arguments.length === 1 && typeof d === 'string' && !this._isValid(d)) {
      m = d
      d = undefined
    }
    if (spare) this.spare = spare

    if (!d && !this._isValid(this.date)) return this.spare
    if (d) {
      this.date = new Date(d)
    }
    this.parsed = Date.parse(this.date)

    if (m === 'x') return this.parsed

    const { offset, userOffset, date } = this

    // const isoOffest = d.match(/([\+-][0-9]{2}\:)/)?.[0]?.replace(':', '')
    const parsedDate = this._split(date, offset, userOffset)

    this.ISO = this.getIso(parsedDate)

    if (m?.toUpperCase() === 'ISO') {
      this._reset()

      return this.ISO
    } else {
      let mask
      if (m !== 'l') {
        mask = m || 'DD.MM.YYYY • HH:mm'
      } else mask = 'D/M/YY'
      return this._parseMask(mask, parsedDate)
    }
  },
  // Chaining items
  zone(offset) {
    this.userOffset = offset
    return this
  },
  calc(quantity, unit, date) {
    this.date = date
      ? this._isValid(date)
        ? new Date(date)
        : this.date
      : this.date

    if (!this.date) return this

    const u = this.units[unit]
    const time = this.date.getTime()
    this.date.setTime(time + quantity * u)

    return this
  },
  zeroing(date) {
    this.date = date
      ? this._isValid(date)
        ? new Date(date)
        : this.date
      : this.date

    if (!this.date) return this

    this.date.setHours(0)
    this.date.setMinutes(0)
    this.date.setSeconds(0)
    return this
  },
  // Helpers
  secondsToTime: (initial) => {
    const twoDigit = (v) => {
      return v >= 10 ? v : `0${v}`
    }

    const hours = Math.floor(initial / (60 * 60))
    const divisor_for_minutes = initial % (60 * 60)
    const minutes = Math.floor(divisor_for_minutes / 60)
    const divisor_for_seconds = divisor_for_minutes % 60
    const seconds = Math.ceil(divisor_for_seconds)

    return `${twoDigit(hours)}:${twoDigit(minutes)}:${twoDigit(seconds)}`
  }
}
