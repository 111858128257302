<template>
  <r-bottom-sheet
    ref="card"
    :overlay="false"
    class="vehicle-create"
    initial-height="50%"
    max-height="100%"
    title="Новое транспортное средство"
    close-button
    @closed="close">
    <div class="vehicle-create">
      <div v-loading="loading" class="r-modal-card__controls">
        <r-button type="success" :disabled="saveDisabled" @click="create">
          Сохранить ТС
        </r-button>
        <r-button simple @click="close"> Отмена </r-button>
      </div>
      <attributes-list
        v-if="source"
        :model="model"
        :related="related"
        :source="source"
        is-editing />
    </div>
  </r-bottom-sheet>
</template>

<script>
import { model, related } from '../config/vehicle'
import attributesList from '@/components/globals/r-modal-elements/attributes-list'
import { notifyFactory } from '@/utils'

export default {
  components: {
    attributesList
  },
  data() {
    return {
      model,
      related,
      source: null,
      loading: false
    }
  },
  computed: {
    saveDisabled() {
      return (
        this.source && !!Object.values(this.source).filter((s) => !s).length
      )
    }
  },
  mounted() {
    this.$refs.card.open()
  },
  async created() {
    await this.loadRelated()

    this.source = model.reduce((a, c) => {
      a[c.model] = null
      return a
    }, {})
  },
  methods: {
    close() {
      this.$store.commit('SET', ['vehicleCreating', false])
    },
    async loadRelated() {
      this.loading = true

      for (const r in this.related) {
        const urlName = this.related[r].urlName
        const source_id = this.$store.state.services[urlName]
        if (!source_id) return

        try {
          const config = JSON.stringify(this.related[r].config)
          const url = `objectInfo/${source_id}?config=${config}`

          const { data } = await this.$store.dispatch('GET_REQUEST', {
            url
          })

          this.related[r].data = Object.values(data)
        } catch (e) {
          console.error(e)
        }
      }
      this.loading = false
    },
    async create() {
      this.loading = true

      try {
        const data = this.source
        await this.$store.dispatch('PUT_REQUEST', {
          url: `objectInfo/${this.$store.state.services.vehicles}`,
          data
        })
        this.$emit('updateRelated')
        this.$notify(notifyFactory('succcess', 'ТС успешно создано'))
      } catch (e) {
        console.error(e)
        this.$notify(notifyFactory('error', 'Произошла ошибка'))
      } finally {
        this.loading = false
        this.close()
      }
    }
  }
}
</script>

<style lang="scss">
.vehicle-create {
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-gap: 1rem;

  .r-modal-card__content {
    height: 100%;
    padding: 0 0.25rem;
    overflow-y: auto;
  }
}
</style>
