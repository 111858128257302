import Point from '@mapbox/point-geometry'
import { Constants } from './constants'

export function mouseEventPoint(mouseEvent, container) {
  const rect = container.getBoundingClientRect()
  return new Point(
    mouseEvent.clientX - rect.left - (container.clientLeft || 0),
    mouseEvent.clientY - rect.top - (container.clientTop || 0)
  )
}

export function isEventAtCoordinates(event, coordinates) {
  if (!event.lngLat) return false
  return (
    event.lngLat.lng === coordinates[0] && event.lngLat.lat === coordinates[1]
  )
}

export function createVertex(parentId, coordinates, path, selected) {
  return {
    type: Constants.geojsonTypes.FEATURE,
    properties: {
      meta: Constants.meta.VERTEX,
      parent: parentId,
      coord_path: path,
      active: selected ? 'true' : 'false'
    },
    geometry: {
      type: 'Point',
      coordinates
    }
  }
}

export function createMidpoint(parent, startVertex, endVertex, map) {
  const startCoord = startVertex.geometry.coordinates
  const endCoord = endVertex.geometry.coordinates

  // If a coordinate exceeds the projection, we can't calculate a midpoint,
  // so run away
  if (
    startCoord[1] > Constants.LAT_RENDERED_MAX ||
    startCoord[1] < Constants.LAT_RENDERED_MIN ||
    endCoord[1] > Constants.LAT_RENDERED_MAX ||
    endCoord[1] < Constants.LAT_RENDERED_MIN
  ) {
    return null
  }

  const ptA = map.project([startCoord[0], startCoord[1]])
  const ptB = map.project([endCoord[0], endCoord[1]])
  const mid = map.unproject([(ptA.x + ptB.x) / 2, (ptA.y + ptB.y) / 2])

  return {
    type: Constants.geojsonTypes.FEATURE,
    properties: {
      meta: Constants.meta.MIDPOINT,
      parent,
      lng: mid.lng,
      lat: mid.lat,
      coord_path: endVertex.properties.coord_path
    },
    geometry: {
      type: Constants.geojsonTypes.POINT,
      coordinates: [mid.lng, mid.lat]
    }
  }
}

export function createSupplementaryPoints(
  geojson,
  options = {},
  basePath = null
) {
  const { type, coordinates } = geojson.geometry
  const featureId = geojson.properties && geojson.properties.id

  let supplementaryPoints = []

  if (type === Constants.geojsonTypes.POINT) {
    // For points, just create a vertex
    supplementaryPoints.push(
      createVertex(featureId, coordinates, basePath, isSelectedPath(basePath))
    )
  } else if (type === Constants.geojsonTypes.POLYGON) {
    // Cycle through a Polygon's rings and
    // process each line
    coordinates.forEach((line, lineIndex) => {
      processLine(
        line,
        basePath !== null ? `${basePath}.${lineIndex}` : String(lineIndex)
      )
    })
  } else if (type === Constants.geojsonTypes.LINE_STRING) {
    processLine(coordinates, basePath)
  } else if (type.indexOf(Constants.geojsonTypes.MULTI_PREFIX) === 0) {
    processMultiGeometry()
  }

  function processLine(line, lineBasePath) {
    let firstPointString = ''
    let lastVertex = null
    line.forEach((point, pointIndex) => {
      const pointPath =
        lineBasePath !== undefined && lineBasePath !== null
          ? `${lineBasePath}.${pointIndex}`
          : String(pointIndex)
      const vertex = createVertex(
        featureId,
        point,
        pointPath,
        isSelectedPath(pointPath)
      )

      // If we're creating midpoints, check if there was a
      // vertex before this one. If so, add a midpoint
      // between that vertex and this one.
      if (options.midpoints && lastVertex) {
        const midpoint = createMidpoint(
          featureId,
          lastVertex,
          vertex,
          options.map
        )
        if (midpoint) {
          supplementaryPoints.push(midpoint)
        }
      }
      lastVertex = vertex

      // A Polygon line's last point is the same as the first point. If we're on the last
      // point, we want to draw a midpoint before it but not another vertex on it
      // (since we already a vertex there, from the first point).
      const stringifiedPoint = JSON.stringify(point)
      if (firstPointString !== stringifiedPoint) {
        supplementaryPoints.push(vertex)
      }
      if (pointIndex === 0) {
        firstPointString = stringifiedPoint
      }
    })
  }

  function isSelectedPath(path) {
    if (!options.selectedPaths) return false
    return options.selectedPaths.indexOf(path) !== -1
  }

  // Split a mulr-geometry into constituent
  // geometries, and accumulate the supplementary points
  // for each of those constituents
  function processMultiGeometry() {
    const subType = type.replace(Constants.geojsonTypes.MULTI_PREFIX, '')
    coordinates.forEach((subCoordinates, index) => {
      const subFeature = {
        type: Constants.geojsonTypes.FEATURE,
        properties: geojson.properties,
        geometry: {
          type: subType,
          coordinates: subCoordinates
        }
      }
      supplementaryPoints = supplementaryPoints.concat(
        createSupplementaryPoints(subFeature, options, index)
      )
    })
  }

  return supplementaryPoints
}

export const doubleClickZoom = {
  enable(ctx) {
    setTimeout(() => {
      // First check we've got a map and some context.
      if (
        !ctx.map ||
        !ctx.map.doubleClickZoom ||
        !ctx._ctx ||
        !ctx._ctx.store ||
        !ctx._ctx.store.getInitialConfigValue
      ) {
        return
      }
      // Now check initial state wasn't false (we leave it disabled if so)
      if (!ctx._ctx.store.getInitialConfigValue('doubleClickZoom')) return
      ctx.map.doubleClickZoom.enable()
    }, 0)
  },
  disable(ctx) {
    setTimeout(() => {
      if (!ctx.map || !ctx.map.doubleClickZoom) return
      // Always disable here, as it's necessary in some cases.
      ctx.map.doubleClickZoom.disable()
    }, 0)
  }
}
