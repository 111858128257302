<template>
  <div>
    <r-title type="title-3">
      {{ item.title }}
    </r-title>
    <r-title v-if="item.value" type="title-3">
      {{ item.value }}
    </r-title>
    <div v-else-if="item.values">
      <r-title v-for="(val, i) in item.values" :key="i" type="title-3">
        {{ val }}
      </r-title>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
      default: () => null
    }
  }
}
</script>
