import upperFirst from 'lodash.upperfirst'
import camelCase from 'lodash.camelcase'

function loadComponents(app) {
  const requireComponent = require.context('.', false, /r-[\w-]+\.vue$/)

  requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName)
    const componentName = upperFirst(camelCase(fileName.replace(/\.\w+$/, '')))

    app.component(componentName, componentConfig.default || componentConfig)
  })
}

export default loadComponents
