import { rDate, notifyFactory } from '@/utils'

export function errorParser(e, message = null, title = null) {
  let errorCode
  if (e.response) errorCode = e.response.data.no

  const errorCodeText = `Ошибка №${errorCode}`
  let isKnownError

  if (errorCode >= 1000 && errorCode <= 1084) isKnownError = errorCode
  let errorMessage
  let errorTitle

  title ? (errorTitle = title) : (errorTitle = errorCodeText)

  if (message && errorCode) {
    errorMessage = `${message} ${errorCodeText}`
  } else if (isKnownError && errorCode) {
    switch (isKnownError) {
      case 1009:
        errorMessage = e.response.data.body
          ? `Ошибка №${errorCode} ${e.response.data.body.source_id}`
          : `Ошибка №${errorCode}`
        break
      case 1052:
        if (e.response.data.body) {
          const expirationTime = rDate.format(e.response.data.body.time)
          errorMessage = `Ошибка №${errorCode} ${expirationTime}`
        } else {
          errorMessage = `Ошибка №${errorCode}`
        }
        break
      case 1073:
        errorMessage = e.response.data.body
          ? `Ошибка №${errorCode} ${e.response.data.body.source_id}`
          : `Ошибка №${errorCode}`
        break
      case 1074:
      case 1075:
        errorMessage = e.response.data.body
          ? `${e.response.data.body.msg}`
          : 'Обратитесь к администратору'
        break
      default:
        errorMessage = `Ошибка №${errorCode}`
        break
    }
  } else {
    errorTitle = 'Ошибка!'
    errorMessage = 'Обратитесь к администратору'
  }

  this.$notify(notifyFactory('error', errorTitle, errorMessage))
}
