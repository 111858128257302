<template>
  <div v-if="canRender && !loading" v-loading="loading" class="r-filter-set">
    <el-popover
      v-for="filter in filters"
      :key="filter.id"
      v-model="popoverVisible[`id_${filter.id}`]"
      placement="bottom"
      trigger="click"
      class="r-filter-set__item filter-item"
      popper-class="r-filter-set__popper">
      <r-button
        slot="reference"
        :active="filter.active"
        type="secondary"
        dropdown
        uppercase
        bold>
        <r-icon v-if="filter.icon" :name="filter.icon" :size="20" />
        <span class="filter-item__title">
          {{ `${filter.title}${getFilterValues(filter)}` }}
        </span>
        <span class="arrow-down" />
      </r-button>
      <component
        :is="filter.type"
        :id="filter.id"
        :ref="filter.id"
        :filter-value="filter.prop"
        :format="filter.format"
        :picker-type="filter.pickerType"
        @load="pipeData" />
    </el-popover>
    <r-button
      v-if="hasActiveFilters(filters).length && !noResetButton"
      class="r-filter-set__reset-button"
      simple
      bold
      mini
      @click="resetAllFilters">
      Сбросить фильтры
    </r-button>
  </div>
</template>

<script>
import intervalFilter from './r-filter-set/interval-filter'
import checkboxFilter from './r-filter-set/checkbox-filter'
import radioFilter from './r-filter-set/radio-filter'

export default {
  components: { intervalFilter, checkboxFilter, radioFilter },
  props: {
    filters: {
      type: Array,
      required: true
    },
    groupBySelected: {
      type: Boolean,
      default: false
    },
    noResetButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      popoverVisible: {},
      loading: false
    }
  },
  computed: {
    canRender() {
      const { filters } = this
      return filters.length && Object.keys(filters[0]).length !== 0
    }
  },
  methods: {
    hasActiveFilters(filters) {
      return filters.filter((f) => !!f.active)
    },
    resetAllFilters() {
      this.$emit('reset')
      const activeFilters = this.hasActiveFilters(this.filters)
      if (activeFilters.length) {
        this.loading = true

        activeFilters.forEach((f) => {
          const component = this.$refs[f.id][0]
          component.resetFilter()
        })

        this.loading = false
      }
    },
    pipeData(data) {
      this.loading = true
      this.popoverVisible[`id_${data.id}`] = false
      this.$emit('load', data)
      if (this.groupBySelected) this.sortBySelected(this.filters)
      this.loading = false
    },
    sortBySelected(filters) {
      filters.sort((a, b) => (a.active === b.active ? 0 : a.active ? -1 : 1))
    },
    getFilterValues(filter) {
      if (!filter.active) return ''

      switch (filter.type) {
        // — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — //
        case 'checkbox-filter':
          return `: ${filter.prop
            .filter((f) => f.value)
            .map((f) => f.name)
            .join(', ')}`
        // — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — //
        case 'radio-filter':
          return `: ${filter.prop.find((f) => f.value).label}`
        // — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — //
        case 'interval-filter': {
          const { from, to } = filter.prop.interval
          const fFrom = from
            ? ` С ${new Date(from)?.toLocaleDateString()}`
            : null
          const fTo = to ? ` ПО ${new Date(to)?.toLocaleDateString()}` : null

          return `${fFrom && fTo ? `:${fFrom}, ${fTo}` : fFrom || fTo || ''}`
        }
        // — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — — //
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="scss">
$selected: var(--accent_selected);
$active: var(--button_primary_bg);
$hover: var(--accent_hover);
$default: var(--text_subhead);
$panel: var(--bg_panel_primary);
$divider: var(--dividers_low_contrast);
$radius: var(--border_radius);

.r-filter-set {
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  padding: 0 1rem 0.5rem 1rem;
  background-color: $panel;
  display: flex;
  flex-wrap: wrap;

  &__popper {
    .popper-filter__content {
      max-height: 360px;
      overflow: auto;
    }
  }

  &__reset-button {
    margin: 0.5rem 0 0 auto !important;
  }

  .filter-item {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    max-width: 400px;
    overflow: hidden;

    &__title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__button {
      max-width: 400px;
      display: grid;
      grid-auto-flow: column;
      grid-gap: 0.25rem;
      padding-right: 1.25rem;
      border-radius: $radius;
      border: none;
      height: 2.25rem;
      align-items: center;
      color: var(--text_subhead);
      font-weight: 600;
      background-color: transparent;
      cursor: pointer;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      transition: 0.15s;
      position: relative;

      &:hover {
        color: $active;
        background-color: $hover;

        .arrow-down::after {
          background-color: $active !important;
        }

        svg {
          fill: $active;
        }
      }

      &.active {
        background-color: $selected;
        color: $active;

        svg {
          fill: $active;
        }

        &:hover {
          color: $default;

          svg {
            fill: $default;
          }
        }
      }
    }
  }
}
</style>
