<template>
  <div v-loading="loading" class="new-object-card">
    <div v-if="object" class="new-object-card__attributes">
      <sign-attributes
        v-if="type === 'signs'"
        :object="object.properties"
        :signs="signs"
        @add-sign="addSign"
        @remove-sign="removeSign" />
      <event-create-attributes
        v-else-if="type === 'events'"
        :object="object.properties"
        :create-mode="true"
        :geometry-type="object.geometry.type" />
    </div>
    <r-button
      class="new-object-card__footer"
      type="success"
      :loading="saving"
      :disabled="isSaveDisabled"
      @click="saveObject">
      Сохранить
    </r-button>
  </div>
</template>

<script>
import SignAttributes from '@/modules/odd/card/sign-attributes'
import EventCreateAttributes from '@/modules/odd/card/event-create-attributes'
import cloneDeep from 'lodash.clonedeep'
import { signTemplate } from './configs'

export default {
  components: {
    SignAttributes,
    EventCreateAttributes
  },
  data() {
    return {
      loading: false,
      saving: false,
      signs: [],
      signsClone: [],
      object: null
    }
  },
  computed: {
    eventClasses() {
      return this.$store.state.books.eventClasses
    },
    type() {
      const { type } = this.$store.state.editorState

      return type === 'signs' ? 'signs' : 'events'
    },
    newCardId() {
      return !!this.$store.state.newCardId
    },
    isSaveDisabled() {
      if (!this.object) return true

      const { properties } = this.object

      if (this.type === 'signs') {
        const queryAttr = ['name', 'sign_icon_id']
        const signsDataFalse = this.signs?.some((e) => {
          return queryAttr.some((q) => !e[q])
        })
        return !!(!properties?.name || !this.signs?.length || signsDataFalse)
      }
      return !!(!properties?.name && !properties?.event_class_id)
    }
  },
  watch: {
    newCardId() {
      this.getCardData()
    }
  },
  created() {
    this.getCardData()
  },
  methods: {
    async saveSigns() {
      const { ids } = this.$store.state.model
      const url = `objectInfo/${ids.signs}`
      const { properties, geometry } = this.object
      const { name, sign_icon_id } = properties

      const startGeom = {
        type: 'Point',
        coordinates: geometry.coordinates[0]
      }
      const endGeom = {
        type: 'Point',
        coordinates: geometry.coordinates[geometry.coordinates.length - 1]
      }

      const data = [
        {
          name: `${name} (знак)`,
          geom: startGeom,
          projection: startGeom,
          sign_icon_id
        },
        {
          name: `${name} (знак)`,
          geom: endGeom,
          projection: endGeom,
          sign_icon_id
        }
      ]

      await this.$store.dispatch('POST_REQUEST', { url, data })
    },
    saveObject() {
      console.log('save', this.type)
      switch (this.type) {
        case 'events':
          this.saveEvent()
          break
        case 'signs':
          this.saveRack()
          break
      }
    },
    async saveEvent() {
      try {
        this.saving = true

        const { ids } = this.$store.state.model
        const url = `objectInfo/${ids[this.type]}`
        const data = [
          {
            ...this.object.properties,
            geom: this.object.geometry
          }
        ]

        const response = await this.$store.dispatch('POST_REQUEST', {
          url,
          data
        })

        if (this.type === 'events') {
          await this.createAdditionalTable(response)
          this.openCard(response)

          if (this.object.properties.sign_icon_id) {
            await this.saveSigns()
          }
        }

        this.$emit('close')
        if (this.$route.name !== 'odd') this.$router.push('/odd')

        this.$store.commit('SET', ['needToUpdate', true])
      } catch (error) {
        throw new Error(error)
      } finally {
        this.saving = false
      }
    },
    async saveRack() {
      try {
        this.saving = true

        const url = 'objectInfo/telemetry.racks'
        const { properties } = this.object
        if (properties?.sign_icon_id) {
          const { end_time } = properties
          properties.end_time = end_time
            ? this.$rDate.format(end_time, 'iso')
            : null
        }
        const data = [
          {
            ...properties,
            geom: this.object.geometry,
            signs: this.getSigns()
          }
        ]

        await this.$store.dispatch('POST_REQUEST', {
          url,
          data
        })

        this.$emit('close')
        if (this.$route.name !== 'odd') this.$router.push('/odd')

        this.$store.commit('SET', ['needToUpdate', true])

        this.saving = false
      } catch (e) {
        throw new Error(e)
      } finally {
        this.saving = false
      }
    },
    getSigns() {
      const positions = [1, 2, 3, 4, 5]

      this.signs.forEach(({ rack_position }) => {
        const index = positions.findIndex((p) => p === rack_position)

        if (index > -1) {
          positions.splice(index, 1)
        }
      })

      const parsed = this.signs.map((e) => {
        e.geom = this.object.geometry

        if (!e.created_at) {
          delete e.id
          e.rack_position = positions[0]
          positions.splice(0, 1)
        }
        return e
      })

      const deleted = this.signsClone
        .filter((sd) => !this.signs.find((si) => si.id === sd.id))
        .map((s) => {
          s.disabled = true
          return s
        })

      return [...parsed, ...deleted]
    },
    openCard({ data }) {
      const [{ id }] = data

      this.$router.push('/odd')
      this.$store.commit('SET', ['cardId', id])
      this.$store.commit('SET', ['cardType', this.type])
      this.$store.commit('SET', ['needToUpdate', true])
    },
    getAdditionalInfo(event_class_id) {
      const eventClassName = this.eventClasses.find(
        ({ id }) => id === event_class_id
      )?.name
      const additionalEntities = {
        ДТП: { table: 'trafficaccident', modal: 'accident' },
        Недостатки: { table: 'road_block', modal: 'closure' },
        'Ремонтные работы': { table: 'road_block', modal: 'closure' }
      }
      return additionalEntities[eventClassName]
    },
    async createAdditionalTable({ data }) {
      const [{ id, event_class_id }] = data
      const { table } = this.getAdditionalInfo(event_class_id)
      if (!id || !table) return

      const source_id = this.$store.state.services[table]
      const url = `objectInfo/${source_id}`
      await this.$store.dispatch('POST_REQUEST', {
        url,
        data: { event_id: id }
      })
    },
    async getCardData() {
      const { createdObject } = this.$store.state.editorState

      this.object = createdObject

      const rackId = this.object?.rack

      this.signs = []
      this.signsClone = cloneDeep(this.signs)

      if (!rackId) {
        this.addSign()
        return
      }

      try {
        this.loading = true

        const config = {
          where: [
            {
              field: 'id',
              op: '=',
              value: rackId
            }
          ],
          include: {
            signs: {}
          }
        }

        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `objectInfo/telemetry.racks?config=${JSON.stringify(config)}`
        })
        const rack = data[rackId]

        this.object.properties = rack
        this.signs = [...(rack?.signs || [])]
        this.signsClone = cloneDeep(this.signs)
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    addSign() {
      this.loading = true

      setTimeout(() => {
        const sign = signTemplate()
        const date = new Date()
        sign.id = this.$rDate.format(date, 'x') + this.signs?.length
        sign.name = `Новый знак от ${this.$rDate.format(date, 'DD.MM.YY')}`

        console.log('sign', sign)
        this.signs.push(sign)
        console.log('this.signs', this.signs)
        this.loading = false
      }, 8)
    },
    removeSign(sign) {
      const index = this.signs.findIndex((s) => s.id === sign.id)

      if (index > -1) {
        this.signs.splice(index, 1)
      }

      this.$emit('remove-sign', sign)
    }
  }
}
</script>

<style lang="scss">
.new-object-card {
  &__footer {
    margin-top: 32px;

    .r-button {
      width: 100%;
    }
  }
}
</style>
