export const prepareDataToSave = function (data, checklistId) {
  const allAttrs = []

  data.forEach((e) => {
    e.lists.forEach((k) => {
      allAttrs.push(...k.items)
    })
  })

  return allAttrs.map((e) => {
    const item = {}

    if (e.exist_id) {
      item.id = e.exist_id
      item._action = 'updated'
    } else {
      item.attr_id = e.id
      item.checklist_id = checklistId
    }
    item.attr_value = String(e.value)
    item.comment = e.comment
    item.name = e.name

    return item
  })
}
