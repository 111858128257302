<template>
  <violations-editor />
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    violationsEditor: defineAsyncComponent({
      loader: () =>
        import('@/modules/violations/violations-editor.vue')
    })
  }
}
</script>
