import { createWebHistory, createRouter } from 'vue-router'

import Main from '@/views/index.vue'
import bddChecklists from '@/views/bdd-checklists/bdd-checklists.vue'
import Violations from '@/views/violations/violations.vue'
import ViolationsList from '@/views/violations/list.vue'
import ViolationsEdit from '@/views/violations/edit.vue'
import ViolationsCreate from '@/views/violations/create.vue'
import Odd from '@/views/odd/odd.vue'
import ObjectList from '@/views/odd/object-list.vue'
import Create from '@/views/odd/create.vue'
import Settings from '@/views/odd/settings.vue'
import store from '@/store'

const defaultPath = store.state.defaultPath

const routes = [
  {
    path: '/',
    name: 'main',
    component: Main,
    children: [
      {
        path: 'odd',
        component: Odd,
        name: 'odd',
        children: [
          {
            path: 'object-list',
            name: 'object-list',
            component: ObjectList
          },
          {
            path: 'create',
            name: 'create',
            component: Create
          },
          {
            path: 'settings',
            name: 'settings',
            component: Settings
          }
        ]
      },
      {
        path: 'bdd-checklists',
        component: bddChecklists,
        name: 'bdd-checklists'
      },
      {
        path: 'violations',
        component: Violations,
        name: 'violations',
        redirect: '/violations/list',
        children: [
          {
            path: 'create',
            name: 'violationsCreate',
            component: ViolationsCreate
          },
          {
            path: 'edit',
            name: 'violationsEdit',
            component: ViolationsEdit
          },
          {
            path: 'list',
            name: 'violationsList',
            component: ViolationsList
          }
        ]
      }
    ]
  },

  {
    name: 'all-routes',
    path: '/:catchAll(.*)',
    redirect: defaultPath
  }
]

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes
})

export default router
