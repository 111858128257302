import {
  removeHoveredSPHelpers,
  removeStopPointsHelpers,
  clearAllRouteGeometry,
  clearActiveStopPointsStyling
} from '@/libs/map-draw/helpers'

export class EditorController {
  constructor(parent) {
    this.parent = parent
    this.mapgl = parent.mapgl
    this.draw = parent.draw
    this.$store = parent.$store
    this.drawEnable = false

    // handlers
    this.createHandler = this.createHandler.bind(this)

    // edit handler
    // this.mapgl.on('draw.update', e => {
    //   const [feature] = e.features

    //   this.$store.commit('SET', ['featureToEdit', feature])
    // })
  }

  // handlers
  createHandler(e) {
    const [feature] = e.features
    const point = feature?.properties?.point

    if (point) {
      const width = 10
      const height = 10
      const bbox = [
        [point.x - width / 2, point.y - height / 2],
        [point.x + width / 2, point.y + height / 2]
      ]
      const [rack] = this.mapgl.queryRenderedFeatures(bbox, {
        layers: ['racks']
      })

      if (rack) {
        feature.rack = rack?.properties?.id
      }
    }

    this.$store.commit('SET', ['editorState.createdObject', feature])
  }

  enableCreateMode() {
    const { editorState } = this.$store.state
    const { type } = editorState

    if (!this.mapgl.hasControl(this.draw)) {
      this.mapgl.addControl(this.draw)
    }

    this.draw.changeMode(`draw_${type}`)

    this.mapgl.on('draw.create', this.createHandler)
    this.drawEnable = true

    // close active card
    this.$store.commit('SET', ['cardId', null])
    this.$store.commit('SET', [
      'cardType',
      type === 'signs' ? 'signs' : 'events'
    ])
  }

  disableEditor() {
    this.toggleEditorStyling(false)
    this.clearHelpers()

    this.parent.helpers.clearCustomState()

    if (this.mapgl.hasControl(this.draw)) {
      this.draw.deleteAll()
      this.mapgl.removeControl(this.draw)
    }

    this.drawEnable = false

    this.$store.commit('CLEAR_EDITOR_STATE')
    this.$store.commit('SET', ['editorState.createdObject', null])

    // remove handlers
    this.mapgl.off('draw.create', this.createHandler)

    // remove signs layers
    if (this.mapgl.getLayer('signs_editor')) {
      this.mapgl.removeLayer('signs_editor')
    }
    if (this.mapgl.getLayer('signs_editor_points')) {
      this.mapgl.removeLayer('signs_editor_points')
    }
    if (this.mapgl.getLayer('signs_editor_connections')) {
      this.mapgl.removeLayer('signs_editor_connections')
    }
    if (this.mapgl.getSource('signs_editor')) {
      this.mapgl.removeSource('signs_editor')
    }
  }

  removeCreatedObject() {
    const { type, createdObject } = this.$store.state.editorState

    if (createdObject) {
      this.draw.deleteAll()
      this.$store.commit('SET', ['editorState.createdObject', null])
    }

    if (type) {
      this.draw.changeMode(`draw_${type}`)
    }

    this.clearHelpers()
  }

  toggleEditorStyling(flag) {
    const { editorState } = this.$store.state
    const { id } = editorState

    if (flag) {
      const value = ['case', ['==', ['get', 'id'], id], 0.2, 1]

      this.mapgl.setPaintProperty('signs_points', 'circle-opacity', value)
      this.mapgl.setPaintProperty(
        'signs_points',
        'circle-stroke-opacity',
        value
      )
      this.mapgl.setPaintProperty('signs_symbols', 'icon-opacity', value)
      this.mapgl.setPaintProperty('signs_connections', 'line-opacity', value)
      this.mapgl.setPaintProperty('events_points', 'icon-opacity', value)
      this.mapgl.setPaintProperty('events_lines', 'line-opacity', value)
      this.mapgl.setPaintProperty('events_lines_icons', 'icon-opacity', value)

      return
    }

    this.mapgl.setPaintProperty('signs_points', 'circle-opacity', 1)
    this.mapgl.setPaintProperty('signs_points', 'circle-stroke-opacity', 1)
    this.mapgl.setPaintProperty('signs_symbols', 'icon-opacity', 1)
    this.mapgl.setPaintProperty('signs_connections', 'line-opacity', 1)
    this.mapgl.setPaintProperty('events_points', 'icon-opacity', 1)
    this.mapgl.setPaintProperty('events_lines', 'line-opacity', 1)
    this.mapgl.setPaintProperty('events_lines_icons', 'icon-opacity', 1)
  }

  clearHelpers() {
    // clear sp helpers
    removeHoveredSPHelpers(this.mapgl)
    removeStopPointsHelpers(this.mapgl)
    // clear route helpers
    clearAllRouteGeometry(this.mapgl)
    // clearReverseRouteStyling(this.mapgl)
    clearActiveStopPointsStyling(this.mapgl, this.$store)
  }
}
