<template>
  <div :class="getObjectClass" @click="clickHandler">
    <div class="odd-object-item__icon">
      <template v-if="type === 'signs'">
        <r-icon v-if="!object.sign_icon" :size="24" name="image" />
        <icon-image v-else :size="24" :resource_id="resource_id" />
      </template>
      <template v-else>
        <r-icon :size="28" :name="eventIcon" />
      </template>
    </div>
    <r-text>
      {{ object.name }}
    </r-text>
  </div>
</template>

<script>
import iconImage from '@/modules/odd/list/icon-image'

export default {
  components: { iconImage },
  props: {
    object: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    cardId() {
      return this.$store.state.cardId
    },
    getObjectClass() {
      return this.object.id === this.cardId
        ? 'odd-object-item odd-object-item--active'
        : 'odd-object-item'
    },
    resource_id() {
      return this.object.sign_icon.resource_id
    },
    eventIcon() {
      switch (this.object.event_class?.name) {
        case 'Недостатки':
          return 'closed'
        case 'Ремонтные работы':
          return 'work'
        case 'ДТП':
          return 'car-accident'
        default:
          return 'closed'
      }
    }
  },
  methods: {
    clickHandler() {
      const { id } = this.object

      this.$router.push('/odd')
      this.$store.commit('SET', ['cardId', id])
      this.$store.commit('SET', ['cardType', this.type])
    }
  }
}
</script>

<style lang="scss">
.odd-object-item {
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;

  &.odd-object-item--active {
    background-color: var(--accent_active) !important;
  }

  &:hover {
    background-color: var(--accent_hover) !important;
  }

  &__icon {
    margin-right: 8px;
  }
}
</style>
