<template>
  <attachments label="Загрузить файлы" hide-list :source="source" />
</template>

<script>
import { parseImage } from '@/utils'
import attachments from '@/components/globals/attachments/attachments'

export default {
  components: { attachments },
  props: {
    source_id: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      source: {
        file: []
      }
    }
  },
  watch: {
    source: {
      handler: function (val) {
        if (!val?.file?.length) return
        this.uploadFiles(val.file)
      },
      deep: true
    }
  },
  methods: {
    async uploadFiles(filesList) {
      this.$emit('loading', ['files', true])

      try {
        await Promise.all(
          filesList.map(async (e) => {
            const parsedImage = await parseImage(e)

            await this.uploadFile(parsedImage, e)
          })
        )
        this.$notify({
          message: 'Загрузка прошла успешно',
          duration: 1500,
          type: 'success',
          title: 'Загрузка'
        })
        this.source = { file: [] }
        this.$emit('load')
      } catch (e) {
        this.$notify({
          message: `Не удалось загрузить фото, ошибка: ${e}`,
          duration: 5000,
          type: 'error',
          title: 'Ошибка'
        })
        throw new Error(e)
      } finally {
        this.$emit('loading', ['files', false])
      }
    },
    async uploadFile(image, source) {
      try {
        const formData = new FormData()
        image.filename = source.name

        formData.append('source_id', this.source_id)
        formData.append('obj_id', this.id)
        formData.append('file', image, source.name)

        await this.$store.dispatch('POST_REQUEST', {
          url: 'upload_resource',
          data: formData
        })
      } catch (e) {
        throw new Error(e)
      }
    }
  }
}
</script>
