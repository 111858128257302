<template>
  <div class="r-ritm-tabs__list-item" :class="{ active: isActive }">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    id: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      thisIsTab: true,
      isActive: false
    }
  },
  watch: {
    active(v) {
      this.isActive = v
    }
  },
  created() {
    this.$parent.tabs?.push(this)
    this.isActive = this.active
  },
  methods: {
    setActive(v) {
      this.isActive = v
    }
  }
}
</script>

<style lang="scss">
.r-ritm-tabs__list-item {
  display: none;

  &:empty {
    display: none !important;
  }

  &.active {
    height: 100%;
    overflow: hidden;
    display: grid;
  }
}
</style>
