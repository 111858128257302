<template>
  <div id="app-main">
    <el-config-provider :locale="ruLocale">
      <router-view />
    </el-config-provider>
  </div>
</template>

<script>
import { toggleBodyTheme } from '@/utils'
import { ElConfigProvider } from 'element-plus'
import ruRU from 'element-plus/es/locale/lang/ru'

export default {
  components: {
    ElConfigProvider
  },
  async created() {
    await this.syncStorageData()

    this.disableScale()

    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    this.initResizeEffect()
  },
  computed: {
    ruLocale() {
      return ruRU
    }
  },
  methods: {
    initResizeEffect() {
      window.addEventListener('resize', () => {
        const vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
      })
    },
    async syncStorageData() {
      console.log('localStorage.initialState', localStorage.initialState)
      if (localStorage.initialState) {
        const initialState = JSON.parse(localStorage.initialState)
        const keys = Object.keys(initialState)
        keys.forEach((key) => {
          if (initialState[key]) {
            this.$store.commit('SET', [
              `initialState.${key}`,
              initialState[key]
            ])
          }
        })
      }
      const darkTheme = JSON.parse(localStorage.getItem('darkTheme'))
      console.log('darkTheme', darkTheme)
      this.$store.commit('SET', ['darkTheme', darkTheme ?? true])

      toggleBodyTheme()
    },
    disableScale() {
      let lastTouchEnd = 0
      document.addEventListener(
        'touchend',
        (e) => {
          const now = new Date().getTime()
          if (now - lastTouchEnd <= 300) {
            e.preventDefault()
          }
          lastTouchEnd = now
        },
        false
      )
    }
  }
}
</script>
