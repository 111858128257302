import { Constants } from '@/libs/map-draw/lib-utils'

export function getPointFeatures(map, editor, e, type, isRoute) {
  const pixels = isRoute ? Constants.ROUTE_PIXELS : Constants.PIXELS
  const layer = editor.modelLayers.find((l) => l.datatype === type)
  if (!layer.visible || layer.loading) return []
  const layerId = editor.isMap || type === 'stop_points' ? layer.id : type
  const { x, y } = e.point
  const bbox = [
    [x - pixels, y - pixels],
    [x + pixels, y + pixels]
  ]
  const completeLayerId = type === 'zones' ? `${layerId}_center_geom` : layerId

  if (!map.getLayer(completeLayerId)) return []

  return map.queryRenderedFeatures(bbox, {
    layers: [completeLayerId]
  })
}

export function getLinkFeatures(map, editor, e) {
  const pixels = 50
  const layer = editor.modelLayers.find((l) => l.datatype === 'links')
  if (!layer.visible || layer.loading) return []
  const layerId = editor.isMap ? layer.id : 'links'
  const { x, y } = e.point
  const bbox = [
    [x - pixels, y - pixels],
    [x + pixels, y + pixels]
  ]

  if (!map.getLayer(layerId)) return []

  return map.queryRenderedFeatures(bbox, {
    layers: [layerId]
  })
}

export const getTCBaseFeatures = (map, parent, e, type, pixelsValue) => {
  const pixels = pixelsValue || Constants.PIXELS
  const id = parent.$store.state.trafficCount.model.ids[type]
  const { x, y } = e.point
  const bbox = [
    [x - pixels, y - pixels],
    [x + pixels, y + pixels]
  ]
  const layerId = type === 'zones' ? `${id}_center_geom` : id

  if (!map.getLayer(layerId)) return []

  return map.queryRenderedFeatures(bbox, {
    layers: [layerId]
  })
}

export const getFeaturesByLayerId = (map, e, layerId, pixels = 50) => {
  const { x, y } = e.point
  const bbox = [
    [x - pixels, y - pixels],
    [x + pixels, y + pixels]
  ]

  if (!map.getLayer(layerId)) return []

  return map.queryRenderedFeatures(bbox, {
    layers: [layerId]
  })
}
