<template>
  <r-bottom-sheet
    ref="settings"
    :overlay="false"
    class="settings-card"
    max-height="50%"
    initial-height="50%"
    title="Настройки"
    close-button
    @closed="close">
    <settings :settings="settings" />
  </r-bottom-sheet>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    settings: defineAsyncComponent({
      loader: () => import('@/components/settings')
    })
  },
  data() {
    return {
      settings: [
        { id: 1, title: 'Цветовая тема интерфейса', component: 'themeToggler' },
        { id: 2, title: 'Подложка карты', component: 'baseLayerToggler' },
        {
          id: 3,
          title: 'Перейти к выбору модуля',
          component: 'backToAppSelect'
        }
      ]
    }
  },
  mounted() {
    this.$refs.settings.open()
  },
  methods: {
    close() {
      this.$router.push('/odd')
    }
  }
}
</script>

<style lang="scss">
.settings-card {
  .r-bottom-sheet__content {
    padding: 0 1rem 0.5rem 1rem !important;
  }
}
</style>
