// Main
// import Vue from 'vue'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import vSelect from 'vue-select'
import { svgSpriteDirectivePlugin } from 'vue-svg-sprite'

// eslint-disable-next-line import/no-duplicates
import {
  ElButton as Button,
  ElSelect as Select,
  ElOption as Option,
  ElDatePicker as DatePicker,
  ElTimePicker as TimePicker,
  ElInput as Input,
  ElCheckbox as Checkbox,
  ElRadio as Radio,
  ElRadioGroup as RadioGroup,
  ElRadioButton as RadioButton,
  ElTabs as Tabs,
  ElTabPane as TabPane,
  ElTooltip as Tooltip,
  ElDropdown as Dropdown,
  ElDropdownMenu as DropdownMenu,
  ElDropdownItem as DropdownItem,
  ElNotification as Notification,
  ElMessage as Message,
  ElMessageBox as MessageBox,
  // ElLoading as Loading,
  ElSwitch as Switch,
  ElUpload as Upload,
  ElDialog as Dialog,
  ElPopover as Popover,
  ElInputNumber as InputNumber,
  ElIcon as Icon
} from 'element-plus'
// import lang from './local/element_local'
import 'element-plus/theme-chalk/src/index.scss'
// import 'element-plus/es/components/date-picker/style/css'
// Styles
import '@/styles/main.scss'
import '@/styles/ritm-variables.scss'
import loader from './loader'

// Utils
import { rDate } from '@/utils'
import loadComponents from '@/components/globals'
// Drag, swipe & touch
// import VueRecognizer from 'vue-recognizer'

// import elementZhTWLocale from './local/element_local'

// vSelect.props.components.default = () => ({
//   OpenIndicator: {
//     render: (createElement) => createElement('span', ' ')
//   }
// })
const app = createApp(App)

// Element components
app.use(Button)
app.use(Select)
app.use(Option)
app.use(Input)
app.use(TimePicker)
app.use(Tabs)
app.use(TabPane)
app.use(DatePicker)
app.use(Radio)
app.use(RadioGroup)
app.use(Checkbox)
app.use(RadioButton)
app.use(InputNumber)
app.use(DropdownMenu)
app.use(DropdownItem)
app.use(Dropdown)
app.use(Switch)
app.use(Upload)
app.use(Tooltip)
app.use(Dialog)
app.use(Popover)
app.use(Icon)

app.config.globalProperties.$confirm = MessageBox.confirm
app.config.globalProperties.$notify = Notification
app.config.globalProperties.$message = Message
app.config.globalProperties.$rDate = rDate

// app.component('VSelect', vSelect)

// app.use(VueRecognizer)

// app.use(Loading.directive)
app.directive('loading', loader)

app.use(svgSpriteDirectivePlugin, {
  url: '/sprite/symbol/main.svg'
})

app.use(router)
app.use(store)

loadComponents(app)

app.mount('#app')
