<template>
  <div
    class="r-text"
    :class="[type, colorType, { center }]"
    :style="getStyle()">
    <slot />
  </div>
</template>

<script>
const textTypes = ['default', 'caption']

export default {
  props: {
    type: {
      validator: (value) => textTypes.indexOf(value) !== -1,
      default: 'default'
    },
    color: {
      type: String,
      default: null
    },
    size: {
      type: Number,
      default: null
    },
    noWrap: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    },
    weight: {
      type: Number,
      default: null
    },
    colorType: {
      type: String,
      default: ''
    }
  },
  methods: {
    getStyle() {
      const styles = {}

      if (this.size) styles['font-size'] = `${this.size}px`

      if (this.color) styles.color = `${this.color} !important`

      if (this.noWrap) styles['white-space'] = 'nowrap'

      if (this.weight) styles['font-weight'] = `${this.weight} !important`

      return styles
    }
  }
}
</script>

<style lang="scss" scoped>
.r-text {
  // title-module, text-mid, text-input
  font-size: 14px;
  color: var(--text_primary) !important;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  line-height: 1.5;
  // additional
  &.caption {
    font-size: 12px;
    color: var(--text_secondary) !important;

    &.primary {
      color: var(--text_primary) !important;
    }
  }

  &.center {
    text-align: center;
  }

  &.error {
    color: var(--accent_error) !important;
  }

  &.field-placeholder {
    color: var(--field_placeholder) !important;
  }

  &.accent-primary {
    color: var(--accent_primary) !important;
  }

  &.accent-warning {
    color: var(--accent_warning) !important;
  }

  &.accent-success {
    color: var(--accent_success) !important;
  }

  &.subhead {
    color: var(--text_subhead) !important;
  }

  &.secondary {
    color: var(--text_secondary) !important;
  }

  &.white {
    color: var(--white_white) !important;
  }
}
</style>
