import { mapColors } from '@/config/colors'

export const getWaypointsQuery = (points) =>
  points.reduce((prev, el, i) => {
    const current = `${el.id}`
    const result = i === 0 ? current : `${prev},${current}`
    return result
  }, '')
export const createLineGeoJson = (coordinates) => ({
  type: 'Feature',
  geometry: {
    type: 'LineString',
    coordinates
  }
})
export const getLineRouteItemsCoordinates = (points) =>
  points.map(({ geom, stop_point, x, y }) => {
    if (stop_point) {
      if (stop_point.geom) {
        return stop_point.geom.coordinates
      }

      return stop_point.coordinates
    }
    if (geom) return geom.coordinates
    if (x && y) return [x, y]

    return []
  })
export const getLineRouteIds = (points) =>
  points.map(({ node_id, stop_point_id }) => ({
    id: node_id || stop_point_id
  }))
export const modifyLineRouteItems = (features) => {
  features.forEach((f) => {
    const { properties } = f
    const { line_route_items } = properties

    properties.line_route_items = line_route_items.map((point) => {
      const geom = point.node_id ? point.node.geom : point.stop_point.geom
      delete point.node
      delete point.stop_point

      return { ...point, geom }
    })
  })
}

// route notify helpers
export const showStopsWarnings = (component) => {
  component.$message({
    message: 'Маршрут должен начинаться и заканчиваться в остановках',
    type: 'warning',
    duration: 1500,
    customClass: 'route-editor-warning'
  })
}

// route map helpers
export const routeConfig = {
  type: 'line',
  paint: {
    'line-color': mapColors.activeColor,
    'line-width': 4
  },
  layout: {
    'line-cap': 'round',
    'line-join': 'round'
  }
}
export const routeArrowsConfig = {
  type: 'symbol',
  layout: {
    'symbol-placement': 'line',
    'text-field': '▶',
    'text-size': ['interpolate', ['linear'], ['zoom'], 12, 16, 22, 18],
    'symbol-spacing': ['interpolate', ['linear'], ['zoom'], 12, 32, 22, 45],
    'text-keep-upright': false
  },
  paint: {
    'text-color': mapColors.activeColor,
    'text-halo-color': mapColors.white,
    'text-halo-width': 1.3
  }
}

export const routePointsConfig = {
  paint: {
    'circle-radius': ['match', ['get', 'type'], 'stop', 6, 6],
    'circle-color': mapColors.white,
    'circle-pitch-alignment': 'map',
    'circle-stroke-width': ['match', ['get', 'type'], 'stop', 2, 2],
    'circle-stroke-color': mapColors.activeColor
  }
}

export const createRouteHelper = (map, coordinates, onlyArrows, onlyLine) => {
  const data = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: { type: 'LineString', coordinates }
      }
    ]
  }

  if (!map.getSource('route-helper')) {
    map.addSource('route-helper', {
      type: 'geojson',
      lineMetrics: true,
      data
    })
  } else map.getSource('route-helper').setData(data)

  if (!map.getLayer('route-helper') && !map.getLayer('route-arrows-helper')) {
    if (!onlyArrows) {
      map.addLayer({
        id: 'route-helper',
        source: 'route-helper',
        ...routeConfig
      })
    }

    if (!onlyLine) {
      const arrowOptions = {
        id: 'route-arrows-helper',
        source: 'route-helper',
        ...routeArrowsConfig
      }
      const vertexId = 'gl-draw-polygon-and-line-vertex-stroke-inactive.cold'

      if (map.getLayer(vertexId) && onlyArrows) {
        map.addLayer(arrowOptions, vertexId)
      } else map.addLayer(arrowOptions)
    }
  }
}

export const clearAllRouteGeometry = (map) => {
  if (map.getLayer('route-helper')) map.removeLayer('route-helper')
  if (map.getLayer('route-arrows-helper')) {
    map.removeLayer('route-arrows-helper')
  }
  if (map.getSource('route-helper')) map.removeSource('route-helper')
  if (map.getLayer('route-points-helper')) {
    map.removeLayer('route-points-helper')
  }
  if (map.getSource('route-points-helper')) {
    map.removeSource('route-points-helper')
  }
}

export const createRoutePoints = (map, points) => {
  const data = {
    type: 'FeatureCollection',
    features: points.map((coordinates, i) => ({
      type: 'Feature',
      geometry: { type: 'Point', coordinates },
      properties: { type: i === 0 || i === points.length ? 'start' : 'stop' }
    }))
  }

  if (!map.getSource('route-points-helper')) {
    map.addSource('route-points-helper', {
      type: 'geojson',
      data
    })
    map.addLayer({
      id: 'route-points-helper',
      type: 'circle',
      source: 'route-points-helper',
      ...routePointsConfig
    })
  } else {
    map.getSource('route-points-helper').setData(data)
  }
}

// reverse route styling
const hotLayer = 'gl-draw-line-inactive.hot'
const coldLayer = 'gl-draw-line-inactive.cold'

export const setReverseRouteStyling = (map, id) => {
  const value = [
    'match',
    ['get', 'id'],
    id,
    mapColors.reverseColor,
    mapColors.routeColor
  ]

  if (map.getLayer(hotLayer)) {
    map.setPaintProperty(hotLayer, 'line-color', value)
  }
  if (map.getLayer(coldLayer)) {
    map.setPaintProperty(coldLayer, 'line-color', value)
  }
}

export const clearReverseRouteStyling = (map) => {
  if (map.getLayer(hotLayer)) {
    map.setPaintProperty(hotLayer, 'line-color', mapColors.routeColor)
  }
  if (map.getLayer(coldLayer)) {
    map.setPaintProperty(coldLayer, 'line-color', mapColors.routeColor)
  }
}

// active stop points styling
export const setActiveStopPointsStyling = (map, store, ids) => {
  const spId = store.state.model.ids.stop_points
  const uniqueIds = ids.filter((id, i) => ids.indexOf(id) === i)
  const value = [
    'match',
    ['get', 'id'],
    uniqueIds,
    mapColors.activeColor,
    mapColors.routeColor
  ]

  if (map.getLayer('sp_points')) {
    map.setPaintProperty('sp_points', 'circle-color', value)
  }
  if (map.getLayer('sp_connectors')) {
    map.setPaintProperty('sp_connectors', 'line-color', value)
  }
  if (map.getLayer(spId)) {
    map.setPaintProperty(spId, 'circle-stroke-color', value)
  }
}

export const clearActiveStopPointsStyling = (map, store) => {
  const spId = store.state.model.ids.stop_points

  if (map.getLayer('sp_points')) {
    map.setPaintProperty('sp_points', 'circle-color', mapColors.routeColor)
  }
  if (map.getLayer('sp_connectors')) {
    map.setPaintProperty('sp_connectors', 'line-color', mapColors.routeColor)
  }
  if (map.getLayer(spId)) {
    map.setPaintProperty(spId, 'circle-stroke-color', mapColors.white)
  }
}
