<template>
  <button>
    <r-icon name="cross" :size="22" />
  </button>
</template>

<style lang="scss" scoped>
button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
}
</style>
