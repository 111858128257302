<template>
  <el-dialog
    title="Выберите фотографии, которые хотите прикрепить"
    :model-value="isShow"
    :before-close="handleClose"
    width="95%">
    <div class="checklist-files-select-modal">
      <r-title v-if="subtitle" type="title-2">
        {{ subtitle }}
      </r-title>
      <checklist-album select-mode :attr-id="attrId" />
    </div>
  </el-dialog>
</template>

<script>
import checklistAlbum from '../checklist-album/checklist-album'

export default {
  components: { checklistAlbum },
  methods: {
    handleClose(done) {
      this.isShow = false
      done()
    }
  },
  computed: {
    attrId() {
      return this.$store.state.bdd.selectModalAttrId
    },
    subtitle() {
      return this.$store.state.bdd.selectModalAttrName
    },
    isShow: {
      get() {
        return this.$store.state.bdd.selectModalShow
      },
      set(value) {
        this.$store.commit('BDD_SET_FIELD', {
          field: 'selectModalShow',
          value
        })
        if (!value) {
          this.$store.commit('BDD_SET_FIELD', {
            field: 'selectModalAttrId',
            value: null
          })
          this.$store.commit('BDD_SET_FIELD', {
            field: 'selectModalAttrName',
            value: null
          })
        }
      }
    }
  }
}
</script>

<style lang="scss">
.el-dialog {
  &__header {
    padding: 1rem 0.5rem 0.5rem 1rem !important;
  }

  &__body {
    padding: 0.5rem 1rem 1rem !important;
  }
}

.checklist-files-select-modal {
  display: grid;
  grid-gap: 1rem;

  .r-title {
    position: relative;
    padding: 0.5rem;
    border-radius: var(--border_radius);
    background-color: var(--bg_containers);
  }
}
</style>
