// const getBBox = map => {
//   const { mapgl } = map
//   const bounds = mapgl.getBounds()
//   const { _ne, _sw } = bounds

//   return [
//     [_sw.lng, _ne.lat],
//     [_ne.lng, _ne.lat],
//     [_ne.lng, _sw.lat],
//     [_sw.lng, _sw.lat]
//   ]
// }

const onlyConfig = { only: ['id', 'geom', 'no'] }
const getIncludeConfig = (geom_type) => {
  switch (geom_type) {
    case 'nodes':
      return {
        include: {
          from_links: onlyConfig,
          to_links: onlyConfig,
          connectors: { only: ['id', 'geom'] },
          stop_points: onlyConfig
        }
      }
    case 'links':
      return {
        include: {
          stop_point: onlyConfig
        }
      }
  }
}

export const getEditorRequestConfig = (map, geom_type) => {
  return {
    limit: map.getObjectsLimit(geom_type),
    only: ['id', 'geom'],
    where: [
      // {
      //   field: 'geom',
      //   op: 'bbox',
      //   type: 'AND',
      //   value: getBBox(map)
      // },
      {
        field: 'geom',
        op: '!null',
        type: 'AND',
        value: ''
      }
    ],
    ...getIncludeConfig(geom_type)
  }
}

export const getFirstSymbolId = (mapgl, type = 'symbol') => {
  const { layers } = mapgl.getStyle()

  const symbolIndex = layers.findIndex((e) => e.type === 'symbol')

  if (layers[23]) {
    return layers[23].id
  }

  if (symbolIndex !== -1) {
    return layers[symbolIndex].id
  }

  return layers[layers.length - 1].id
}
