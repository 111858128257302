<template>
  <div class="checklist-inspection-list">
    <div class="checklist-inspection-list__header">
      <el-switch
        v-if="list.isShow"
        v-model="list.isShow.value"
        class="r-switch" />
      <r-title v-if="list.title" type="subtitle-2">
        {{ list.title }}
      </r-title>
    </div>
    <div v-if="isShow" class="checklist-inspection-list__content">
      <checklist-inspection-list-item
        v-for="item in list.items"
        :key="item.id"
        :docs="docs"
        :has-docs="list.id === 0"
        :source="source"
        :item="item"
        :always-show-button="list.id === 0" />
    </div>
  </div>
</template>

<script>
import checklistInspectionListItem from './checklist-inspection-list-item'

export default {
  components: { checklistInspectionListItem },
  props: {
    source: {
      type: Object,
      required: true
    },
    docs: {
      type: Array,
      required: true
    },
    list: {
      type: Object,
      required: true,
      default: () => null
    }
  },
  computed: {
    isShow() {
      return this.list.isShow ? this.list.isShow.value : true
    }
  }
}
</script>

<style lang="scss" scoped>
.checklist-inspection-list {
  display: grid;
  grid-gap: 0.5rem;
  border-bottom: 1px solid var(--field_border);

  &:last-child {
    border-bottom: none;
  }

  &__content {
    display: grid;
    grid-gap: 1rem;
  }
}
</style>
