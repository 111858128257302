<template>
  <div class="odd-card-controls">
    <template v-if="$route.name === 'odd'">
      <r-block col no-padding start>
        <r-button type="success" :loading="saving" @click="$emit('save')">
          Сохранить объект
        </r-button>
        <r-delete-button mini @delete="$emit('delete')" />
      </r-block>
      <r-block col no-padding end>
        <r-button v-if="hasRack" :loading="saving" @click="$emit('open-rack')">
          Карточка стойки
        </r-button>
        <r-button icon="focus-zone" @click="$emit('flyTo')" />
      </r-block>
    </template>
    <r-delete-button v-else mini @delete="$emit('delete')" />
  </div>
</template>

<script>
export default {
  props: {
    saving: {
      type: Boolean,
      default: false
    },
    hasRack: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.odd-card-controls {
  display: grid;
  grid-gap: 0.5rem;
  padding: 0.5rem;
  border-bottom: 1px solid var(--dividers_low_contrast) !important;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;

  &__button-group {
    display: grid;
    grid-gap: 0.5rem;
  }
}
</style>
