export const getDatasourcesByDatatype = async (
  component,
  where = [],
  include = {}
) => {
  const config = {
    where,
    include
  }
  const { data } = await component.$store.dispatch('GET_REQUEST', {
    url: `permission_object?config=${JSON.stringify(config)}`
  })
  const datasources = Object.values(data)

  datasources.forEach((data) =>
    component.$store.commit('SET_DATASOURCE_FROM_TREE', {
      id: data.id,
      data
    })
  )

  return datasources || []
}

export const getModelChildrenIds = (children) =>
  children.reduce((prev, ch) => {
    prev[ch.datatype] = ch.id

    return prev
  }, {})
