<template>
  <button @click="$emit('back')">
    <r-icon name="back" :size="18" />
  </button>
</template>

<style lang="scss" scoped>
button {
  background-color: transparent;
  border: 0;
}
</style>
